import {
  isArray,
  isObject,
  keys,
  isBoolean,
  isNumber,
  toString,
  set,
  isString,
  forEach,
} from 'lodash';
const _ = { isArray, isObject, keys, isBoolean, isNumber, toString, set };

const filterProperty = [
  'pageOption', // 翻页数据
  'filter', // 筛选数据
  'filter1',
  'filter2',
];

// // 特殊字符替换，如果是在like模式下，需要将%单独做一轮转换
function escapeSpecialChars(str, likeMode = false) {
  if (!isString(str)) return str;
  let resStr = str;
  likeMode && (resStr = resStr.replaceAll('\\', '\\\\'));
  try {
    JSON.parse(`"${resStr}"`);
    likeMode && (resStr = resStr.replaceAll('\\', '\\\\')); // likeMode模式下，需要在做一次类似stringify的动作替换反斜杠
  } catch (e) {
    const newStr = JSON.stringify(resStr);
    const len = newStr.length;
    resStr = newStr.substring(1, len - 1);
  }
  if (likeMode) {
    if (/^%(.*)%$/g.test(resStr)) {
      resStr = `%${resStr.substring(1, resStr.length - 1).replaceAll('%', '\\\\%')}%`;
    } else {
      resStr = resStr.replaceAll('%', '\\\\%');
    }
  }
  return resStr;
}
function getValue(val, includeSpecial, likeMode) {
  if (_.isArray(val)) {
    return `[${val.map((item) => getValue(item)).join(',')}]`;
  }
  if (_.isObject(val)) {
    const result = _.keys(val)
      .map((key) => {
        return `${key}:${getValue(val[key])}`;
      })
      .join(',');
    return `{${result}}`;
  }
  if (_.isBoolean(val) || _.isNumber(val)) {
    return val;
  }
  if (isString(val) && includeSpecial) {
    return `"${_.toString(escapeSpecialChars(val, likeMode))}"`;
  }
  // null 值不能被转换为空字符串啊
  return val === null ? null : `"${_.toString(val)}"`;
}

function calVariables(query, data = {}) {
  let outQuery = query;
  const saveData = Object.assign({}, data);

  // 筛选条件默认为空
  filterProperty.forEach((key) => {
    if (!saveData[key]) saveData[key] = '';
  });
  _.keys(saveData).forEach((key) => {
    const value = saveData[key];
    const replaceKey = `$${key}`;
    if (filterProperty.includes(key)) {
      outQuery = outQuery.replace(replaceKey, value === null ? null : value || '');
    } else {
      outQuery = outQuery.replaceAll(replaceKey, getValue(value));
    }
  });

  return outQuery; // .replaceAll('\n', '');
}

function calVariablesLoop({ base = '', query = '' }, data = []) {
  let outQuery = data
    .map((item) => {
      return calVariables(query, item);
    })
    .join(',');
  outQuery = base.replace('$data', outQuery || '');
  return outQuery;
}

function handleResult(res, queryGql = '', payload) {
  if (!res.data) return {};
  const data = _.isObject(res.data) ? res.data : JSON.parse(res.data);
  // 如果是增删改动作，而返回结果中update_where_id_num的值，与实际更新的id数不一致，则认为没有操作权限
  if (isString(queryGql) && /^mutation/gi.test(queryGql.trim()) && !payload?.only_validate) {
    const updateWhereIdNum = res?.update_where_id_num;
    if (isNumber(updateWhereIdNum) && updateWhereIdNum !== 0) {
      let retDataCount = 0;
      forEach(data, (item, key) => {
        if (isString(key)) {
          retDataCount += item?.length || 0;
        }
      });
      if (retDataCount !== updateWhereIdNum) {
        throw new Error('当前用户无权限编辑');
      }
    }
  }
  // 增加审批流相关的逻辑，注入结果中。
  if (res?.workflow_conf_sw !== undefined && data) {
    data.__workflow_conf_sw = res.workflow_conf_sw;
  }
  // 注入对象校验规则结果，单个错误
  if (res?.__rule_check_result) {
    _.set(data, '__rule_check_result', res.__rule_check_result);
  }
  // 注入对象校验规则结果，多个错误
  if (res?.__rule_check_results) {
    _.set(data, '__rule_check_results', res.__rule_check_results);
  }
  return data;
}

// {
//   status:[1,2],
//   type:'between'
// }
const typeKey = 'whereGqlType';
function optionGql(option) {
  let optionKey = '';
  let optionValue = '';
  _.keys(option).forEach((key) => {
    if (key !== typeKey) {
      optionKey = key;
      optionValue = option[key];
    }
  });
  const optionType = option[typeKey];
  if (!(optionValue || optionValue === 0 || optionValue === false)) {
    return '';
  }
  if (optionType === 'like') {
    return `{${optionKey}:{_like:${getValue(optionValue)}}}`;
  }
  if (optionType === 'min') {
    return `{${optionKey}:{_gte:${getValue(optionValue)}}}`;
  }
  if (optionType === 'max') {
    return `{${optionKey}:{_lte:${getValue(optionValue)}}}`;
  }
  if (optionType === 'eq') {
    return `{${optionKey}:{_eq:${getValue(optionValue)}}}`;
  }
  if (optionType === 'in') {
    return `{${optionKey}:{_in:${getValue(optionValue)}}}`;
  }
  if (optionType === 'is') {
    return `{${optionKey}:${getValue(optionValue)}}`;
  }
  if (optionType === 'include_any' || optionType === 'include_all') {
    return `{${optionKey}:{_${optionType}:${getValue(optionValue)}}}`;
  }
}

// relation: _and,_or
function whereGql(options = [], relation = '_and', { cascade = false } = {}) {
  const optionGqls = [];
  options.forEach((option) => {
    if (typeof option === 'string') {
      optionGqls.push(option);
    } else {
      const optionValue = optionGql(option);
      optionValue && optionGqls.push(optionValue);
    }
  });
  if (optionGqls.length > 1) {
    if (cascade) return `{${relation}:[${optionGqls.join(',')}]}`;
    return `_where:{${relation}:[${optionGqls.join(',')}]}`;
  }
  if (optionGqls.length > 0) {
    if (cascade) return `${optionGqls[0]}`;
    return `_where:${optionGqls[0]}`;
  }
  if (cascade) return ``;
  return '_where: {}';
}

function pageGql(pageIndex, pageSize) {
  return `_limit: ${pageSize}, _offset: ${(pageIndex - 1) * pageSize}`;
}

function jsonStringGql(json) {
  return JSON.stringify(json).replaceAll('"', '\\"');
}

export {
  calVariables,
  calVariablesLoop,
  handleResult,
  getValue,
  whereGql,
  pageGql,
  jsonStringGql,
  escapeSpecialChars,
};
