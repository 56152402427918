import { isString, keys, isArray, merge, cloneDeep, includes, intersection } from 'lodash';
import { PAGE_SIZE, PROP_LABEL, PROP_SPLIT } from '../constant';

import {
  getFieldItem,
  transItem,
  searchGql,
  searchCondtion,
  whereGql,
  whereCondition,
  getFieldMap,
  queryField,
  getWhereMap,
  transUins,
  getFieldOrderMap,
  formatReservedWord,
  getDistinctOnMap,
} from './util';
import { query } from '../util';
import { execExpression } from 'nges-common/src/layout/expression';

let api;
let loginSDK;
let getToken;

api = require('../web/utils/api');
loginSDK = require('../web/utils/login');
getToken = loginSDK?.default?.getToken;

const { GetUserLayoutList } = api;
const _ = { isString, keys, isArray, merge };
// json的默认where
function whereItem({ objects, json, item }) {
  const tmpItem = {};
  if (item.field && !item.value_type) {
    const fieldItem = getFieldItem({ field: item.field, objects, json });
    tmpItem.value_type = fieldItem?.value_type;
  }
  const whereStr = whereGql({ ...tmpItem, force: true, ...item });
  return whereStr;
}
function queryWhereGql({ objects, json }) {
  const { where } = json;
  if (_.isString(where)) return where;
  if (!where) return '';
  // 兼容非wheres和relation
  const otherWheres = [];
  const whereArr = [];
  _.keys(where).forEach((key) => {
    if (!['wheres', 'relation'].includes(key)) {
      const whereStr = whereItem({
        objects,
        json,
        item: { field: key, value: where[key] },
      });
      if (whereStr) otherWheres.push(whereStr);
    }
  });
  if (where?.wheres?.length) {
    where.wheres.forEach((item) => {
      const whereStr = whereItem({ objects, json, item });
      if (whereStr) whereArr.push(whereStr);
    });
  }
  let andWheres = [];
  if (where.relation && where.relation !== '_and' && whereArr.length > 1) {
    andWheres = [...otherWheres, `{${where.relation}:[${whereArr.join(',')}]}`];
  } else {
    andWheres = [...otherWheres, ...whereArr];
  }
  if (andWheres.length > 1) {
    return `{_and:[${andWheres.join(',')}]}`;
  }
  return andWheres.join('');
}
function filterGql(filters, filtersRelation) {
  let filterArr = [];
  const uins = [];
  if (filters?.length) {
    filters
      .filter((filter) => !filter.ignore)
      .forEach((filter) => {
        const filterWhere = whereGql({
          ...filter,
          value:
            filter.selected_key && filter.selected
              ? filter.selected.map((item) => item[filter.selected_key])
              : filter.selected,
          filters,
        });
        if (_.isArray(filter.selected) && filter.selected.length) {
          filter.selected.forEach((item) => {
            if (item.account) uins.push(item.account);
          });
        }
        if (filterWhere) {
          filterArr.push(filterWhere);
        }
      });
    if (filtersRelation && filterArr.length > 1) {
      filterArr = [`{${filtersRelation}:[${filterArr.join(',')}]}`];
    }
  }
  return { filterArr, uins };
}
function queryGql({
  json,
  objects = [],
  params = {},
  customWhere = '',
  customGql = '',
  filters,
  input,
  columns,
  outerQueryCondition = [], // 外部传入的 where，
}) {
  const { object, distinct, render } = json;
  // 对配置中含有ignore字段的field，不进行获取，有前端页面自行处理
  let { fields = [] } = json;
  fields = fields.filter((item) => !item.ignore);
  const { search = {}, filters_relation: filtersRelation } = render;
  const { limit = PAGE_SIZE, offset = 0 } = params;
  const fieldMap = getFieldMap({
    fields,
    // graphql主表是否忽略id字段，默认都加id
    ignore_id: json.ignore_id,
    ignore_record_type: json.ignore_record_type,
  });
  // 默认where条件
  const defaultWhere = queryWhereGql({ json, objects });
  let inputWhere = '';
  if (input && !search.ignore) {
    inputWhere = searchGql(input, search.fields, json);
  }
  const { filterArr, uins } = filterGql(filters, filtersRelation);
  const whereArr = [...filterArr];
  const whereMap = getWhereMap(json);
  if (whereMap[object]?._where) whereArr.push(whereMap[object]._where);
  if (outerQueryCondition[object]?._where) whereArr.push(outerQueryCondition[object]._where);
  if (defaultWhere) {
    whereArr.push(defaultWhere);
  }
  if (inputWhere) {
    whereArr.push(inputWhere);
  }
  // if (customWhere) {
  //   whereArr.push(customWhere);
  // }
  let whereStr = '';
  if (whereArr.length > 1) {
    whereStr = `{_and:[${whereArr.join(',')}]}`;
  } else if (whereArr.length) {
    whereStr = whereArr.join('');
  }
  if (whereStr) {
    whereStr = `,_where:${whereStr}`;
  }
  const orderMap = getFieldOrderMap(json);
  let mainOrderStr = orderMap?.[object]?._order_by?.orderStr || '';
  if (mainOrderStr) {
    mainOrderStr = `,_order_by:${mainOrderStr}`;
  }
  let distinctStr = '';
  if (distinct === true) {
    distinctStr = '_distinct:true,';
  }
  const distinctOnMap = getDistinctOnMap(json);
  let distinctOnStr = distinctOnMap?.[object]?._distinct_on || '';
  if (distinctOnStr) {
    distinctOnStr = `_distinct_on:${distinctOnStr},`;
  }
  const graphqlFieldStr = queryField(
    fieldMap,
    _.merge({}, whereMap[object], orderMap[object], distinctOnMap[object]),
  );
  let aggregateStr = json?.aggregate;
  // 处理聚合配置
  if (columns?.length && !aggregateStr) {
    // 多层级字段不支持聚合
    const aggregates = columns.filter(
      (column) => column?.aggregate?.type && !column.field.includes('.'),
    );
    if (aggregates?.length) {
      const aggregateTypeMap = {};
      aggregates.map((column) => {
        aggregateTypeMap[column.aggregate.type] = aggregateTypeMap[column.aggregate.type] || [];
        aggregateTypeMap[column.aggregate.type].push(column.field);
      });
      // 聚合gql语句
      aggregateStr = Object.keys(aggregateTypeMap)
        .filter((aggregateType) => aggregateTypeMap[aggregateType]?.length)
        .map((aggregateType) => {
          return `${aggregateType} { ${aggregateTypeMap[aggregateType].join(' ')} }`;
        })
        .join(' ');
    }
  }
  let queryStr = `query{
    ${object}(${distinctStr}${distinctOnStr}_limit:${limit},_offset:${offset}${mainOrderStr}${whereStr}${
    customWhere ? `,${customWhere}` : ''
  }){
                      _aggregate{
                        _count
                        ${aggregateStr ? `,${aggregateStr}` : ''}
                      },${graphqlFieldStr}${customGql}
                  }
    ${json.sub_query || ''}
              }`;
  queryStr = transUins(queryStr, uins);
  return queryStr;
}
export function generateQueryGqlWhere({ filters, input, json, objects = [] }) {
  const { render } = json;
  let inputWhere = '';
  const { search = {}, filters_relation: filtersRelation } = render;
  if (input && !search.ignore) {
    inputWhere = searchGql(input, search.fields, json);
  }
  // 默认where条件
  const defaultWhere = queryWhereGql({ json, objects });
  let filterArr = [];
  const uins = [];
  if (filters?.length) {
    filters.forEach((filter) => {
      const filterWhere = whereGql({
        ...filter,
        value:
          filter.selected_key && filter.selected
            ? filter.selected.map((item) => item[filter.selected_key])
            : filter.selected,
      });
      if (_.isArray(filter.selected) && filter.selected.length) {
        filter.selected.forEach((item) => {
          if (item.account) uins.push(item.account);
        });
      }
      if (filterWhere) {
        filterArr.push(filterWhere);
      }
    });
    if (filtersRelation && filterArr.length > 1) {
      filterArr = [`{${filtersRelation}:[${filterArr.join(',')}]}`];
    }
  }
  const whereArr = [...filterArr];

  if (defaultWhere) {
    whereArr.push(defaultWhere);
  }
  if (inputWhere) {
    whereArr.push(inputWhere);
  }
  // if (customWhere) {
  //   whereArr.push(customWhere);
  // }
  let whereStr = '';
  if (whereArr.length > 1) {
    whereStr = `{_and:[${whereArr.join(',')}]}`;
  } else if (whereArr.length) {
    whereStr = whereArr.join('');
  }
  return { whereStr, inputWhere, filterWhere: filterArr.join(',') };
}
// 简易版本的基于json生成查询语句，支持order和where查询，后续可拓展
function easyQueryGql(json, extraCondition = {}) {
  if (Array.isArray(json)) {
    const gqls = json.map((queryObj) => {
      const mainObj = queryObj.object;
      const condition = extraCondition[mainObj];
      return getQueryGgl(
        queryObj,
        condition
          ? {
              [mainObj]: condition,
            }
          : {},
      );
    });
    return `query { ${gqls.join('\n')} }`;
  }
  return `query {
    ${getQueryGgl(json, extraCondition)}
  }`;
}

// 获取单个对象的查询语句
function getQueryGgl(json, extraCondition = {}) {
  const { object } = json;
  const fieldMap = { [object]: getFieldMap(json) };
  const orderMap = getFieldOrderMap(json);
  const whereMap = getWhereMap(json);
  const subQuery = json.sub_query;
  const distinctOnMap = getDistinctOnMap(json);
  // const distinctOnStr = distinctOnMap?.[object]?._distinct_on?.distinctOnStr || '';
  const newExtraCondition = { ...extraCondition };
  // if (distinctOnStr) {
  //   if (newExtraCondition[object]) {
  //     newExtraCondition[object]._distinct_on = distinctOnStr;
  //   } else {
  //     newExtraCondition[object] = { _distinct_on: distinctOnStr };
  //   }
  // }
  if (json.distinct) {
    newExtraCondition[object] = newExtraCondition[object] || {};
    newExtraCondition[object]._distinct = true;
  }
  const queryGql = `${queryField(
    fieldMap,
    _.merge({}, whereMap, orderMap, distinctOnMap, newExtraCondition),
  )} ${subQuery || ''}`;
  return formatReservedWord(queryGql);
}

function transData({ json, objects = [], data }) {
  const { object } = json;
  const objectData = data[object];
  let outList = [];
  let total = 0;
  let aggregate = null;
  if (objectData) {
    total = objectData._aggregate.count;
    aggregate = objectData._aggregate;
    const list = objectData._data || [];
    outList = list.map((item) => transItem({ item, objects, json }));
  }
  return { total, list: outList, aggregate };
}

// 获取query重点对象数据
function getLayoutKey() {
  const object = query('object');
  const recordType = query('record_type');
  const usage = query('usage');
  let layoutType = '';
  let path = '';
  
  path = location.pathname;
  
  
  layoutType = path.match(/\/layout(.+)\.html$/)?.[1] || '';
  layoutType = layoutType.toLowerCase();
  
  if (layoutType) {
    layoutType = layoutType === 'index' ? 'list' : layoutType;
    layoutType = includes(['list', 'mutation', 'detail'], layoutType) ? layoutType : '';
    if (layoutType === 'mutation') {
      layoutType = query('id') ? 'edit' : 'create';
    }
  }
  return { object, layoutType, recordType, usage };
}

// 用于缓存对象布局数据
const ObjectLayoutJsonMap = {};
// 新布局获取方式: 通过元数据对象， query.layout = 元数据对象|类型|record_type 获取布局json
async function getNewLayoutJson(layoutKeyParams) {
  const layoutKey = (layoutKeyParams ? layoutKeyParams : getLayoutKey()) || {};
  const { object, layoutType, recordType, usage } = layoutKey;
  if (!(object && layoutType) && !usage) {
    return null;
  }
  const layoutJsonKeyNotoken = object
    ? `${object}|||${layoutType}|||${recordType}`
    : `other|||${usage}`;
  const nowToken = getToken ? getToken() : '';
  if (!getToken) console.log('meownotoken');
  const layoutJsonKey = `${layoutJsonKeyNotoken}${nowToken}`;
  if (ObjectLayoutJsonMap[layoutJsonKey]) {
    return cloneDeep(ObjectLayoutJsonMap[layoutJsonKey]);
  }
  // 获取布局版本
  let version = process.env.NODE_ENV === 'development' ? 'beta' : '';
  // 微信体验版，采用正式环境的灰度环境菜单数据
  
  try {
    const results = await GetUserLayoutList({
      querys: [
        {
          layout_type: object ? 'object' : 'other',
          object_code: object,
          object_record_type: recordType,
          object_layout_type: layoutType,
          usage,
        },
      ],
      version,
    });
    const layout = results?.data?.[0];
    const json = JSON.parse(layout?.layout_json);
    // 注入菜单属性到json中
    json._own_prop = {
      id: `${layout.object_code}_${layout.object_record_type}_${layout.object_layout_type}`, // 对应菜单id
      package: layout.package, // 对应模块
      layout_type: 'OBJECT', // 对象布局
    };
    if (json) {
      ObjectLayoutJsonMap[layoutJsonKey] = json;
      return cloneDeep(json);
    }
  } catch (e) {
    console.log('get Meta Data Layout Json error', e);
  }
  return null;
}

// 兼容pc的fields包含filter和column
// 因为如果强制拆解进render中，会重复配置，所以兼容一下。支持两者
function transJson(json, expParamsObj) {
  const outRender = { columns: [], exportColumns: [], filters: [] };
  const { fields = [], render, row } = json;
  fields.forEach((field) => {
    if (_.isString(field)) {
      outRender.columns.push(field);
      outRender.exportColumns.push(field);
      outRender.filters.push(field);
    } else {
      // execExpression会改变原始值, 重新赋值
      let { hidden } = field;
      if (expParamsObj) {
        hidden = execExpression(hidden, expParamsObj);
      }
      if (field.filter !== false && !hidden) {
        outRender.filters.push({
          ...field,
          label: field.label,
          // 为了与column的field对应
          field: field.field,
          // placeholder和show_type为了保证和小程序类似的配置，先将其配在filed.filter中，这里挪出，
          // 保证类似{ placeholder: "XXX", show_type: "XXX", filter: {} }这样的结构
          placeholder: field?.filter?.placeholder,
          show_type: field?.filter?.show_type,
          filter: {
            field: field.field,
            ...field.filter,
          },
        });
      }
      // true 时，直接过滤，表达式和展示的情况交给后面处理
      if (!(hidden === true)) {
        outRender.columns.push({
          ...field,
          filter: null,
        });
      }
      // 如果设置了export字段为true, 则不管hidden与否，都要导出，若不设置export，则若字段不隐藏，则到处，若export为false，则不导出
      if (field.export || (field.export === undefined && !hidden)) {
        outRender.exportColumns.push({
          ...field,
          filter: null,
        });
      }
    }
  });
  const returnObj = {
    ...json,
    render: {
      search: json.search,
      row: {
        ...row,
      },
      ...outRender,
      ...render,
    },
  };
  // 级联列表
  returnObj.render.cascaderColumns = returnObj.render.columns.filter((column) => column.cascader);
  // 非级联列表才渲染（第一级）
  returnObj.render.columns = returnObj.render.columns.filter((column) => !column.cascader);
  return returnObj;
}

function getColumn({ column, json, objects = [] }) {
  const { field } = column;
  const fieldItem = getFieldItem({ field, objects, json });
  let prop = field.replaceAll('.', PROP_SPLIT);

  const valueType = fieldItem?.value_type;
  if (
    !['CASCADER'].includes(column.value_type) &&
    (column.value_type ||
      ['SELECT_ONE', 'SELECT_MANY', 'DATETIME'].includes(valueType) ||
      column?.format_value)
  ) {
    prop = prop + PROP_LABEL;
  }
  // if (fieldItem) {
  //   const valueType = fieldItem.value_type;
  //   if (['SELECT_ONE', 'SELECT_MANY', 'DATETIME'].includes(valueType)) {
  //     prop = prop + PROP_LABEL;
  //   }
  // }
  return {
    prop,
    ...column,
    label: column.label || fieldItem.display_name || fieldItem.name,
    isRequire: column?.mutation?.rules?.some((rule) => rule.required),
  };
}

function getColumns({ objects, json }) {
  const { columns, exportColumns } = json.render;
  const formatColumnFunc = (column) => {
    if (_.isString(column)) {
      return {
        ...getColumn({
          column: { label: column, field: column },
          objects,
          json,
        }),
      };
    }
    return {
      ...getColumn({ column, objects, json }),
    };
  };
  return {
    columns: columns.map(formatColumnFunc),
    exportColumns: exportColumns.map(formatColumnFunc),
  };
}

export function filterCondition(filters) {
  const filterArr = [];
  const uins = [];
  if (filters?.length) {
    filters
      .filter((filter) => !filter.ignore)
      .forEach((filter) => {
        const filterWhere = whereCondition({
          ...filter,
          value:
            filter.selected_key && filter.selected
              ? filter.selected.map((item) => item[filter.selected_key])
              : filter.selected,
          filters,
        });
        if (_.isArray(filter.selected) && filter.selected.length) {
          filter.selected.forEach((item) => {
            if (item.account) uins.push(item.account);
          });
        }
        if (filterWhere) {
          filterArr.push(filterWhere);
        }
      });
  }
  return { filterArr, uins };
}

// 静态检测数据是否符合要求
function isStaticDataValid(item, filter) {
  if (!filter) {
    return true;
  }
  const data = item?.[filter.field];
  const value = filter?.value;
  if (value === '' || value === 'all' || value === null || value === undefined) {
    return true;
  }
  if (data === null || data === undefined) {
    return false;
  }
  try {
    const { type } = filter;
    if (type === '_like') {
      return includes(data, value);
    }
    if (type === '_ilike') {
      return includes(data.toLowerCase(), value.toLowerCase());
    }
    if (type === '_include_any') {
      return intersection(data, value).length > 0;
    }
    if (type === '_in') {
      return includes(value, data);
    }
    if (type === '_nin') {
      return !includes(value, data);
    }
    if (type === '_between') {
      return data >= value[0] && data <= value[1];
    }
    if (type === '_gt') {
      return data > value;
    }
    if (type === '_gte') {
      return data >= value;
    }
    if (type === '_lt') {
      return data < value;
    }
    if (type === '_lte') {
      return data <= value;
    }
    if (type === '_neq') {
      return data != value;
    }
    return data == value;
  } catch (e) {
    return false;
  }
}

function filterStaticData(list, filterConditions, filtersRelation) {
  if (!filterConditions.length) {
    return list;
  }
  return list.filter((item) => {
    const results = filterConditions.map((filter) => isStaticDataValid(item, filter));
    if (filtersRelation === '_and' || !filtersRelation) {
      return results.every(Boolean);
    }
    if (filtersRelation === '_or') {
      return results.some(Boolean);
    }
    return false;
  });
}

export function getStaticDataByFilters({ list, filters, json, input }) {
  const { render = {} } = json;
  let newList = list;
  // 通过过滤器过滤
  const { filters_relation: filtersRelation, search = {} } = render;
  const filterConditions = filterCondition(filters).filterArr;
  newList = filterStaticData(newList, filterConditions, filtersRelation);
  // 通过全局搜索过滤
  const searchConditions = searchCondtion(input, search.fields, json);
  const searchRelation = json?.render?.search?.relation || '_or';
  newList = filterStaticData(newList, searchConditions, searchRelation);
  return newList;
}

export {
  queryGql,
  transData,
  easyQueryGql,
  getLayoutKey,
  getNewLayoutJson,
  transJson,
  getColumns,
  filterGql,
  queryWhereGql,
};
