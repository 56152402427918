//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isEqual, debounce } from 'lodash';
import { helpGuideUtil } from '../../../layout/util';
import { GetHelpGuide } from '../../../api';
import {
  GetStaffUserInfo,
  GetHelpGuideRead,
  SetHelpGuideRead,
} from 'nges-common/src/web/utils/api';

export default {
  name: 'HelpGuide',
  data() {
    return {
      show: false,
      loading: true,
      confList: [],
      urls: [],
      currentIndex: 0,
      helpID: '',
    };
  },
  watch: {
    show(nv) {
      if (!nv) {
        this.urls = [];
        this.currentIndex = 0;
      }
    },
    $route() {
      // 触发一次对路径的检查
      this.isShow();
    },
  },
  created() {
    this.waiting = new Promise((res) => {
      this.finishWaiting = res;
    });
    this.init();
  },
  beforeDestroy() {
    helpGuideUtil.clearConf();
  },
  methods: {
    saveJson(str) {
      try {
        return JSON.parse(str);
      } catch (err) {
        console.error(err);
        return '';
      }
    },
    async init() {
      try {
        helpGuideUtil.showHelpGuideCb.push(this.isShow);
        const userInfo = await GetStaffUserInfo();
        const res = await GetHelpGuide();
        const helpGuideData = res?.help_guide?.[0] || {};
        const pcConf = helpGuideData.pc_conf;
        this.helpID = helpGuideData.id;
        this.pcConf = (pcConf && JSON.parse(pcConf)) || {};
        this.confId = res?.help_guide?.[0]?.id;
        this.activeRole = userInfo?.active_role?.[0];
        for (const key in helpGuideUtil.pageConf) {
          this.isShow(helpGuideUtil.pageConf[key]);
          if (this.show) {
            break;
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
        this.finishWaiting();
      }
    },
    noMore() {
      this.close();
      const urlList = this.urls.map((i) => i.url);
      SetHelpGuideRead(this.helpID, urlList).catch((e) => {
        console.log('helpguide历史写入错误', e);
      });
    },
    close() {
      this.pushHistory();
      this.show = false;
    },
    pushHistory() {
      this.urls
        .map((i) => `${this.confId}_${i.key}_${i.url}`)
        .forEach((i) => {
          if (!helpGuideUtil.history.find((ii) => ii === i)) {
            helpGuideUtil.history.push(i);
          }
        });
    },
    next() {
      const currentIndex = this.currentIndex + 1;
      this.currentIndex = currentIndex % this.urls.length;
    },
    isShow(conf) {
      if (this.loading) {
        this.waiting.then(() => {
          this.isShow(conf);
        });
        return;
      }
      // 已经打开过的帮助在关闭前也不再打开
      if (conf && this.confList.find((i) => isEqual(i, conf))) {
        return;
      }
      if (conf) this.confList.push(conf);
      for (const key in this.pcConf) {
        const { url, path, package: packageName, role } = this.pcConf[key] || {};
        if (Array.isArray(role) && role.includes(this.activeRole)) {
          const isHistoryExit = helpGuideUtil.history.find(
            (i) => i === `${this.confId}_${key}_${url}`,
          );
          if (!isHistoryExit) {
            if (path) {
              const route = window.location.pathname.slice(1) + window.location.search;
              // 与菜单匹配或者与当前页面路径匹配
              if (conf?.id === path || route.includes(path)) {
                if (!this.urls.find((i) => i.key === key)) {
                  this.urls.push({ url, key });
                }
              }
            } else if (packageName) {
              if (conf?.package === packageName) {
                if (!this.urls.find((i) => i.key === key)) {
                  this.urls.push({ url, key });
                }
              }
            }
          }
        }
      }
      if (this.urls.length) {
        this.showUrl();
      }
    },
    showUrl: debounce(function () {
      GetHelpGuideRead(this.helpID)
        .then((res) => {
          const readUrlList = (res.ignore_help_guide_user || []).map((i) => i.url);
          this.urls = this.urls.filter((i) => !readUrlList.includes(i.url));
          if (this.urls.length) {
            this.show = true;
          }
        })
        .catch((e) => {
          console.log('helpguide历史读取错误', e);
          this.show = true;
        });
    }, 500),
  },
};
