//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { execExpression, isExpression } from 'nges-common/src/layout/expression';
import { fieldLabel } from 'nges-common/src/layout/util';
import Action from '../../../action';
import foldChangeRecordVue from './fold-change-record.vue';
import { get } from 'lodash';
import MStateTag from '../../../state-tag';
const _ = { get };

export default {
  components: { foldChangeRecordVue, MStateTag },
  mixins: [Action],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    row: {
      type: [Object, void 0],
      default: void 0,
    },
    expParamsObj: {
      type: [Object, void 0],
      default: () => {},
    },
    index: {
      type: Number,
      default: -1,
    },
    json: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showMore: false,
    };
  },
  computed: {
    expParams() {
      return {
        ...this.expParamsObj,
        $item: this.item,
        $index: this.index,
        json: this.json,
      };
    },
    listItem() {
      const { item, expParams } = this;
      const { row = {} } = this.json?.render || {};
      const listItem = {
        // 左侧节点 color type
        leftType: 'default',
        descs: [],
        tag: {},
        status: {},
        title: '',
        subList: [],
        end: false,
        start: false,
      };

      if (row.title) {
        listItem.title = fieldLabel(item, row.title, expParams);
      }
      // 节点卡片是否结束的标志
      if (isExpression(row.end)) {
        listItem.end = this.execExpression(row.end);
      }
      // 节点卡片左侧样式的标志
      if (isExpression(row.left_type)) {
        listItem.leftType = this.execExpression(row.left_type);
      }
      if (row.status?.label) {
        const hidden = this.execExpression(row.status?.hidden);
        if (hidden !== true) {
          listItem.status = {
            label: row.status?.label ? this.execExpression(row.status?.label) : '',
            type: row.status?.type ? this.execExpression(row.status?.type) : 'default',
            icon: row.status?.icon ? this.execExpression(row.status?.icon) : '',
            state_tag: !!row.status?.state_tag,
          };
        }
      }
      if (row.descs?.length) {
        if (isExpression(row.descs)) {
          const descs = this.execExpression(row.descs);
          if (Array.isArray(descs)) {
            listItem.descs = descs;
          }
        } else {
          row.descs.forEach((field) => {
            const desc = fieldLabel(item, field, expParams);
            if (desc) {
              listItem.descs.push(desc);
            }
          });
        }
      }
      if (row.tag?.label) {
        const hidden = this.execExpression(row.tag?.hidden);
        if (hidden !== true) {
          listItem.tag = {
            label: row.tag?.label ? this.execExpression(row.tag?.label) : '',
            type: row.tag?.type ? execExpression(row.tag.type) : 'default',
          };
        }
      }

      // 多人审批时 多个审批人的子列表
      if (row.row?.row_key) {
        const subList = [];
        const tmpSubList = item[row.row.row_key] || [];

        tmpSubList.forEach((subItem) => {
          // changeLog 为对象变更历史数组
          const subListItem = {
            descs: [],
            title: '',
            status: {},
            changeLog: [],
          };
          const subexpParams = {
            t: subItem,
            $item: subItem,
            p: {
              ...(this.item || {}),
              ...expParams,
            },
            json: this.json,
          };

          if (row.row.title) {
            subListItem.title = fieldLabel(subItem, row.row.title, subexpParams);
          }

          if (_.get(row, 'row.status.field') || _.get(row, 'row.status.label')) {
            const hidden = execExpression(row.row.status?.hidden, subexpParams);
            if (hidden !== true) {
              subListItem.status = {
                label: _.get(row, 'row.status.label')
                  ? execExpression(_.get(row, 'row.status.label'), subexpParams)
                  : '',
                type: _.get(row, 'row.status.type')
                  ? execExpression(_.get(row, 'row.status.type'), subexpParams)
                  : 'default',
              };
            }
          }

          if (_.get(row, 'row.descs')) {
            if (isExpression(_.get(row, 'row.descs'))) {
              const descs = execExpression(_.get(row, 'row.descs'), subexpParams);
              if (Array.isArray(descs)) {
                subListItem.descs = descs;
              }
            } else {
              (_.get(row, 'row.descs') || []).forEach((field) => {
                const desc = fieldLabel(subItem, field, subexpParams);
                if (desc) {
                  subListItem.descs.push(desc);
                }
              });
            }
          }

          if (_.get(row, 'row.change_log_key')) {
            subListItem.changeLog = _.get(subItem, _.get(row, 'row.change_log_key')) || [];
          }
          subList.push(subListItem);
        });
        listItem.subList = subList;
      }
      return listItem;
    },
  },
  mounted() {},
  methods: {
    execExpression(str) {
      return execExpression(str, this.expParams);
    },
  },
};
