import {
  formatTime,
  formatSeconds,
  formatSecondsDuration,
  getPageParams,
  getCurrentPagesInfo,
  bytesToSize,
  toThousands,
} from '../web-mobile/util';
import { getFieldLabel, getFieldValue, whereGql, getFieldKey, fieldClass, transItem } from './util';
import { getValue } from '../graphql';
import {
  forEach,
  trim,
  upperCase,
  snakeCase,
  get,
  set,
  merge,
  isEmpty,
  map,
  uniq,
  uniqBy,
  cloneDeep,
  isEqual,
} from 'lodash';
import dayjs from 'dayjs';
import { Base64 } from 'js-base64';
import { isExpression } from './expressionScript';
import execExpressionNew from './expression-new';
import { consoleConf } from './log';
const { atob, btoa } = Base64;
const _ = {
  forEach,
  trim,
  upperCase,
  snakeCase,
  get,
  set,
  merge,
  isEmpty,
  map,
  uniq,
  uniqBy,
  cloneDeep,
  isEqual,
};

const scope = {
  formatTime,
  formatSeconds,
  formatSecondsDuration,
  bytesToSize,
  toThousands,
  field(item, field) {
    return getFieldLabel(item, field);
  },
  value(item, field) {
    return getFieldValue(item, field);
  },
  fieldClass(item, field) {
    return fieldClass(item, field, true);
  },
  getValue,
  getFieldKey,
  getWhereGqlStr(list) {
    return list
      .map((i) => {
        return whereGql({ ...i });
      })
      .join(',');
  },
  // todo: 暂时屏蔽掉console log等方法
  // log() {},
  log(...rest) {
    consoleConf.originalConsoleLog(...rest);
  },
  _: {
    get: _.get,
    set: _.set,
    merge: _.merge,
    isEmpty: _.isEmpty,
    map: _.map,
    uniq: _.uniq,
    uniqBy: _.uniqBy,
    cloneDeep: _.cloneDeep,
    isEqual: _.isEqual,
  },
  dayjs,
  $utils: {
    transItem,
    getPageParams,
    atob,
    btoa,
    getCurrentPages: getCurrentPagesInfo,
  },
};

// 是否是新表达式方式
function isNewExpression(expressionStr = '') {
  return (
    Object.prototype.toString.call(expressionStr) === '[object String]'
    && expressionStr.length >= 4
    && expressionStr.startsWith('{%')
    && expressionStr.endsWith('%}')
  );
}

const newScope = (() => {
  const funcs = {};
  // 将scope里的方法全部转成蛇形大写方式
  _.forEach(scope, (v, k) => {
    const key = _.trim(_.upperCase(_.snakeCase(k)));
    if (key) {
      funcs[key.replace(/\s/g, '_')] = v;
    }
  });
  return funcs;
})();

export { scope, isExpression, newScope, isNewExpression, execExpressionNew };
